import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  PartnerImage,
} from "./styles";
import { Container, Grid } from "@mui/material";
import BenefitsItem from "./BenefitsItem";
import { motion } from "framer-motion";

const SectionPartner = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Content>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <PartnerImage
                    src="/img/partner.png"
                    alt="Imagem de um celular representando a tela inicial do aplicativo da Cami."
                  />
                </motion.div>
              </Content>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: 100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Title>Seu parceiro de entregas</Title>
                <Subtitle>Rápido, acessível e fácil de usar!</Subtitle>
              </motion.div>

              <motion.div
                viewport={{ once: true }}
                initial={{ y: 100, opacity: 0 }}
                whileInView={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Content>
                  <BenefitsItem text="Peça um veículo e entregue seus itens SEM DEMORA" />
                  <BenefitsItem text="Faça pedidos na hora ou agendade" />
                  <BenefitsItem text="Múltiplas paradas" />
                  <BenefitsItem text="Seja um motorista ou cliente" />
                </Content>
              </motion.div>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionPartner;
