import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  WrapperBenefits,
  WrapperMoneyPig,
  Background,
} from "./styles";
import { Container, Grid } from "@mui/material";
import BenefitsItem from "./BenefitsItem";
import MoneyPig from "../../../components/icons/money-pig";
import TitleIcon from "../../../components/TitleIcon";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { motion } from "framer-motion";

const SectionTruckDriver = () => {
  return (
    <Background>
      <Section>
        <Container>
          <Wrapper>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Content>
                  <motion.div
                    viewport={{ once: true }}
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{
                      x: 0,
                      opacity: 1,
                      transition: {
                        type: "just",
                        duration: 1,
                      },
                    }}
                  >
                    <TitleIcon icon={<LocalShippingIcon />} />

                    <Title>Seja um motorista parceiro</Title>
                    <Subtitle>Faça sua renda!</Subtitle>
                    <WrapperBenefits>
                      <BenefitsItem
                        text="Por que ser um motorista Cami?"
                        description="Tenha a liberdade de entregar quando quiser e aumentar seus ganhos"
                      />
                      <BenefitsItem
                        text="Complemente a sua renda"
                        description="Escolha suas entregas e receba os repasses semanalmente"
                      />
                      <BenefitsItem
                        text="Horários flexíveis"
                        description="Aceite apenas os pedidos que quiser, sem penalidades. Faça seu horário."
                      />
                    </WrapperBenefits>
                  </motion.div>
                </Content>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <Content>
                    <WrapperMoneyPig>
                      <MoneyPig />
                    </WrapperMoneyPig>
                  </Content>
                </motion.div>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </Section>
    </Background>
  );
};

export default SectionTruckDriver;
