import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  background: url("/img/trucker.jpeg");
  background-size: cover;
  min-height: 300px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;

  ${media.greaterThan("medium")`
    min-height: 500px;
  `}
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #fafafa;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;

  ${media.greaterThan("medium")`
    font-size: 40px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #fafafa;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.2px;

  ${media.greaterThan("medium")`
    font-size: 24px;
  `};
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(5px);
  padding: 100px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
`;
