import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.footer`
  background-color: #fff;
  width: 100%;
  padding: 24px 0 0 0;
  border-top: 2px solid #ffc200;
`;

export const WrapperBottomSection = styled.div`
  align-self: center;
  color: #aaa;
  display: flex;
  width: 100%;
`;

export const IconImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const Icon = styled.a`
  text-decoration: none;
  width: 24px;
  height: 24px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;

  ${media.greaterThan("medium")`
    flex-direction: row;

    &:not(:first-child) {
      padding: 0;
      border-top: unset;
    }

    &:first-child {
      padding-bottom: 0;
    }
  `}
`;

export const Column = styled.div`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const ButtonCallToAction = styled.button`
  padding: 10px;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 15px 0;
  width: 50%;
`;

export const Row = styled.div`
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  list-style-type: none;
  gap: 10px;
`;

export const Logo = styled.img`
  max-width: 300px;
  width: 90%;
  margin-bottom: 15px;
`;

export const FooterText = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const StripeText = styled.p`
  font-size: 16px;
  text-align: center;
`;

export const Link = styled.a`
  font-size: 14px;
  text-align: center;
  line-height: 150%;
  font-weight: normal;
  padding: 8px 0;
  text-decoration: none;
  color: #403e3e;
  letter-spacing: -0.2px;

  &:hover {
    color: #ff8a00;
  }
`;

export const List = styled.ul`
  list-style: none;
  & li {
    margin-bottom: 24px;
  }
`;

export const WrapperMainContent = styled.div`
  border-bottom: 1px solid #e8eaec;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${media.greaterThan("medium")`
    flex-direction: row;
  `}
`;

export const ListSocialMedia = styled.ul`
  display: flex;
  justify-content: flex-start;
  list-style: none;
  gap: 16px;
`;

export const IconListItem = styled.li`
  & svg {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
  }
`;

export const SectionTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #212529;
`;

export const BottomSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
  padding: 15px 0;

  ${media.greaterThan("medium")`
    flex-direction: row;
  `}
`;

export const BottomSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  cursor: pointer;
`;

export const CopyRight = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #323339;
`;

export const SectionText = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #323339;
  text-decoration: none;

  ${media.greaterThan("medium")`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  `}
`;

export const Button = styled.a`
  border: none;
  background-color: #ffcb32;
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 8px;

  &:hover {
    background-color: #ffcb32;
    color: #000;
  }
`;
