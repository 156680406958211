import { Title, Section, Content, Subtitle, Button } from "./styles";
import { Container } from "@mui/material";
import TitleIcon from "../../../components/TitleIcon";
import PaidIcon from "@mui/icons-material/Paid";
import { motion } from "framer-motion";

const SectionBanner = () => {
  return (
    <Section>
      <Container>
        <Content>
          <motion.div
            viewport={{ once: true }}
            initial={{ x: -100, opacity: 0 }}
            whileInView={{
              x: 0,
              opacity: 1,
              transition: {
                type: "just",
                duration: 1,
              },
            }}
          >
            <TitleIcon icon={<PaidIcon />} />
            <Title>
              A Cami é o maior e melhor aplicativo de carreto e mudança do
              Brasil.
            </Title>
            <Subtitle>
              Com ele é possível fazer seu transporte sem demora.
            </Subtitle>
            <Button
              href="http://onelink.to/3u73w5"
              target="_blank"
              rel="noopener"
            >
              Cadastre-se já
            </Button>
          </motion.div>
        </Content>
      </Container>
    </Section>
  );
};

export default SectionBanner;
