import { Title, Section, Content, Subtitle, Wrapper } from "./styles";
import { Container } from "@mui/material";
import { motion } from "framer-motion";

const SectionBanner = () => {
  return (
    <Section>
      <Container>
        <Content />
        <Wrapper>
          <motion.div
            viewport={{ once: true }}
            initial={{ y: -100, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "just",
                duration: 1,
              },
            }}
          >
            <Title>Seja motorista Cami e aumente sua renda!</Title>
            <Subtitle>
              Nossos motoristas tem mais liberdade e flexibilidade de horários
            </Subtitle>
          </motion.div>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionBanner;
