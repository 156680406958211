import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  & svg path {
    fill: #53a653;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  line-height: 150%;
  letter-spacing: 0.2px;

  ${media.greaterThan("medium")`
    font-size: 18px;
  `}
`;
