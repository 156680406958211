import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  WrapperImage,
  TruckImage,
  CelphoneImage,
} from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionBanner = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Content>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <Title>
                    Carretos e mudanças,{" "}
                    <strong style={{ color: "#212529" }}>sem demora</strong>,
                    quando e onde quiser.
                  </Title>
                  <Subtitle>
                    De uma forma conveniente e barata, a qualquer momento.
                  </Subtitle>

                  <TruckImage
                    src="/img/mudanca.png"
                    alt="Ilustração de um caminhão com a porta traseira aberta e pessoas levando itens pessoais para dentro dele."
                  />
                </motion.div>
              </Content>
            </Grid>
            <Grid item xs={12} md={6}>
              <WrapperImage>
                <Content>
                  <motion.div
                    viewport={{ once: true }}
                    initial={{ y: -100, opacity: 0 }}
                    whileInView={{
                      y: 0,
                      opacity: 1,
                      transition: {
                        type: "just",
                        duration: 1,
                      },
                    }}
                  >
                    <CelphoneImage
                      src="/img/celphone.png"
                      alt="Imagem de um celular representando a tela inicial do aplicativo da Cami."
                    />
                  </motion.div>
                </Content>
              </WrapperImage>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionBanner;
