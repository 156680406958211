import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  background: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 40px 80px 40px;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ffcb32;
    color: #000;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;
  letter-spacing: -0.2px;

  ${media.greaterThan("large")`
    font-size: 36px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #212529;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 32px;
  letter-spacing: 0.2px;

  ${media.greaterThan("large")`
    font-size: 24px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const WrapperImage = styled.div`
  max-height: 200px;
`;

export const TruckImage = styled.img`
  max-width: 400px;
`;

export const PartnerImage = styled.img`
  max-width: 400px;
  width: 100%;
  height: 100%;

  ${media.greaterThan("large")`
    max-width: 500px;
  `}
`;
