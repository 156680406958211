import {
  WrapperHeader,
  Link,
  Wrapper,
  WrapperItens,
  LogoImage,
  Button,
} from "./styles";
import { Container } from "@mui/material";

const Header = () => {
  return (
    <WrapperHeader>
      <Container>
        <Wrapper>
          <LogoImage src="/img/logo-small.png" />
          <WrapperItens>
            <Link href="/">Início</Link>
            <Link href="/sobre-nos">Sobre nós</Link>
            <Link href="/servicos">Motorista</Link>
            <Button
              href="http://onelink.to/3u73w5"
              target="_blank"
              rel="noopener"
            >
              Download
            </Button>
          </WrapperItens>
        </Wrapper>
      </Container>
    </WrapperHeader>
  );
};

export default Header;
