import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  background: url("/img/truck-toy.png");
  background-size: cover;
  border-radius: 0 0 16px 16px;

  ${media.greaterThan("medium")`
    border-radius: 0 0 24px 24px;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 40px 0 80px 0;
  align-items: center;
  justify-content: center;

  ${media.greaterThan("medium")`
    padding: 120px 40px 120px 40px;
  `}
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  line-height: 150%;
  letter-spacing: 0.2px;

  ${media.greaterThan("medium")`
    font-size: 48px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WrapperImage = styled.div`
  max-height: 300px;
`;

export const HappyGuyImage = styled.img`
  display: flex;
  width: 80%;
  height: 80%;
  border-radius: 24px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;

  ${media.greaterThan("large")`
    width: 100%;
    height: 100%;
  `}
`;
