import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: #fff;
  padding: 24px;
  border-radius: 16px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.02);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.02);
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.02);

  & svg path {
    fill: #212529;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;

  ${media.greaterThan("medium")`
    font-size: 18px;
  `}
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  line-height: 125%;

  ${media.greaterThan("medium")`
    font-size: 16px;
  `}
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
`;
