import { Wrapper, Subtitle, Section, Content, PartnerImage } from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionAboutCami = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: -100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Content>
                  <PartnerImage
                    src="/img/logo.png"
                    alt="Imagem de um celular representando a tela inicial do aplicativo da Cami."
                  />
                </Content>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: 100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Subtitle>
                  Fundada em novembro de 2021, a CAMI surgiu para criar um
                  serviço de carreto e mudança no mesmo dia que facilita a vida
                  de seus clientes.
                  <br />
                  <br />
                  Por meio de nossos aplicativos, um CAMI ajuda os usuários a
                  transportarem o que importa, conectando-os a motoristas
                  parceiros de forma simples e transparente, 24 horas por dia.
                  <br />
                  <br />
                  Nossos motoristas parceiros operam vários tipos de veículos,
                  como carretos e utilitários, e fazem entregas de itens vários
                  tamanhos ..
                  <br />
                  <br />
                  A CAMI se tornou o parceiro estratégico de empresas de todos
                  os tamanhos para resolver seus problemas de entrega de última
                  hora. Desde lojas a redes de varejo, mudanças residenciais e
                  de escritórios entre outros, a Cami ajuda empresas de uma
                  variedade de mercados a entregar seus produtos de acordo com
                  suas necessidades.
                  <br />
                  <br />
                  Graças ao nosso serviço de carreto e mudança no mesmo dia e
                  características como o rastreamento do pedido em tempo real,
                  como empresas não precisam mais investir na operação e
                  manutenção de frotas internas.
                  <br />
                  <br />
                  Além disso, você pode pagar pelos pedidos por meio do nosso
                  aplicativo e receber recibos digitais por e-mail . Será um
                  prazer contribuir para o sucesso da sua empresa e de você!
                </Subtitle>
              </motion.div>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionAboutCami;
