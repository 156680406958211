import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  MissionImage,
} from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionAboutCami = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: -100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Title>Missão</Title>
                <Subtitle>
                  Fazer com que motoristas autônomos, e todas as pessoas,
                  embarcadores economizem tempo e dinheiro. Não só pensando em
                  oferecer qualquer ajuda; mas sim o melhor atendimento ao
                  cliente, g erando confiabilidade e acesso fácil.
                </Subtitle>
              </motion.div>
            </Grid>

            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: 100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Content>
                  <MissionImage
                    src="/img/mission.png"
                    alt="Imagem demonstrando a conexão de diversas plataformas."
                  />
                </Content>
              </motion.div>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionAboutCami;
