import styled from "styled-components";
import media from "styled-media-query";

export const WrapperHeader = styled.div`
  height: 70px;
  background-color: #fff;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
`;

export const Link = styled.a`
  font-size: 16px;
  color: #323232;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  cursor: pointer;
  text-decoration: none;
  padding: 8px 8px;
  border-radius: 8px;

  &:hover {
    transition: 0.3s ease-in-out;
    background-color: #212529;
    color: #fff;
  }
`;

export const LogoImage = styled.img`
  width: 70px;
  height: 70px;
  display: none;

  ${media.greaterThan("medium")`
    display: flex;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("medium")`
    justify-content: space-between;
  `}
`;

export const WrapperItens = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  ${media.greaterThan("medium")`
    gap: 40px;
  `}
`;

export const Button = styled.a`
  border: none;
  background-color: #f25757;
  transition: 0.3s ease-in-out;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding: 0 8px;
  border-radius: 8px;
  cursor: pointer;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  ${media.greaterThan("medium")`
    padding: 0px 16px;
  `}

  &:hover {
    background-color: #212529;
    color: #fff;
  }
`;
