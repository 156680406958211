import { Wrapper, Text } from "./styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const BenefitsItem = ({ text }) => {
  return (
    <Wrapper>
      <CheckCircleOutlineIcon />
      <Text>{text}</Text>
    </Wrapper>
  );
};

export default BenefitsItem;
