import SectionBanner from "./SectionBanner";
import SectionPartner from "./SectionPartner";
import SectionReady from "./SectionReady";
import SectionTruckDriver from "./SectionTruckDriver";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Home = () => {
  document.title = "Cami | Home";
  return (
    <>
      <Header />
      <SectionBanner />
      <SectionPartner />
      <SectionReady />
      <SectionTruckDriver />
      <Footer />
    </>
  );
};

export default Home;
