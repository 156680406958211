import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  Button,
  ReadyImage,
  WrapperDownload,
} from "./styles";
import { Container, Grid } from "@mui/material";
import TitleIcon from "../../../components/TitleIcon";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import { motion } from "framer-motion";

const SectionReady = () => {
  return (
    <motion.div
      viewport={{ once: true }}
      initial={{ x: -100, opacity: 0 }}
      whileInView={{
        x: 0,
        opacity: 1,
        transition: {
          type: "just",
          duration: 1,
        },
      }}
    >
      <Section>
        <Container>
          <Wrapper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Content>
                  <TitleIcon icon={<PlayCircleFilledWhiteIcon />} />
                  <Title>Pronto para pedir?</Title>
                  <Subtitle>
                    Baixe o aplicativo no seu celular e conheça uma solução
                    completa de carretos, e sua mudança residêncial.
                  </Subtitle>
                  <WrapperDownload>
                    <Button target="_blank" href="http://onelink.to/3u73w5">
                      Baixar
                    </Button>
                  </WrapperDownload>
                </Content>
              </Grid>
              <Grid item xs={12} md={6}>
                <Content>
                  <ReadyImage
                    src="/img/ready.png"
                    alt="Imagem de um celular representando a tela inicial do aplicativo da Cami."
                  />
                </Content>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </Section>
    </motion.div>
  );
};

export default SectionReady;
