import SectionBanner from "./SectionBanner";
import SectionAboutCami from "./SectionAboutCami";
import SectionVision from "./SectionVision";
import SectionMission from "./SectionMission";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const AboutUs = () => {
  document.title = "Cami | Sobre nós";
  return (
    <>
      <Header />
      <SectionBanner />
      <SectionAboutCami />
      <SectionVision />
      <SectionMission />
      <Footer />
    </>
  );
};

export default AboutUs;
