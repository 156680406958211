import SectionBanner from "./SectionBanner";
import SectionTips from "./SectionTips";
import SectionCallBack from "./SectionCallBack";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Services = () => {
  document.title = "Cami | Motorista";
  return (
    <>
      <Header />
      <SectionBanner />
      <SectionTips />
      <SectionCallBack />
      <Footer />
    </>
  );
};

export default Services;
