import { Wrapper, Text, Description, WrapperText } from "./styles";
import { Container, Grid } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const BenefitsItem = ({ text, description }) => {
  return (
    <Wrapper>
      <ChevronRightIcon />
      <WrapperText>
        <Text>{text}</Text>
        <Description>{description}</Description>
      </WrapperText>
    </Wrapper>
  );
};

export default BenefitsItem;
