import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 203, 38, 1) 40%,
    rgba(255, 194, 0, 1) 76%,
    rgba(255, 223, 128, 1) 90%,
    rgba(255, 223, 128, 1) 100%,
    rgba(255, 204, 45, 1) 100%
  );
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 40px 80px 40px;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ffcb32;
    color: #000;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  line-height: 150%;
  margin-bottom: 16px;
  letter-spacing: -0.2px;

  ${media.greaterThan("medium")`
    margin-bottom: 32px;
    font-size: 48px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 24px;
  color: #212529;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
  margin-bottom: 32px;

  ${media.greaterThan("medium")`
    font-size: 32px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const WrapperImage = styled.div`
  max-height: 300px;
`;

export const TruckImage = styled.img`
  max-width: 500px;
  width: 100%;
  height: 100%;

  ${media.greaterThan("medium")`
    max-width: 600px;
  `}
`;

export const CelphoneImage = styled.img`
  display: none;
  width: 100%;
  height: 100%;

  ${media.greaterThan("large")`
    display: flex;
  `}
`;
