import {
  Wrapper,
  Title,
  Section,
  Content,
  WrapperImage,
  HappyGuyImage,
} from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionBanner = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: -100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Content>
                  <Title>Conheça um pouco sobre nós</Title>
                </Content>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <WrapperImage>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ x: 100, opacity: 0 }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <Content>
                    <HappyGuyImage
                      src="/img/happy-guy.png"
                      alt="Imagem de uma pessoa segurando um celular e com um fundo na cor amarelo"
                    />
                  </Content>
                </motion.div>
              </WrapperImage>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionBanner;
