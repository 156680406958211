import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  background: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0px;
  align-items: center;
  justify-content: center;

  ${media.greaterThan("medium")`
    padding: 40px 0px 40px 0px;
  `}
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;
  letter-spacing: 0.2px;
  margin-bottom: 8px;

  ${media.greaterThan("large")`
    font-size: 36px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #403e3e;
  font-weight: 400;
  margin-bottom: 32px;
  line-height: 150%;
  letter-spacing: -0.2px;

  ${media.greaterThan("large")`
    font-size: 18px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
`;

export const VisionImage = styled.img`
  max-width: 400px;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  display: none;

  ${media.greaterThan("medium")`
    display: flex;
  `}
`;
