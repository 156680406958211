import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  background: #fff;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 0 60px 0;
  align-items: center;
  justify-content: center;

  ${media.greaterThan("medium")`
    padding: 80px 0px 80px 0px;
  `}
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;
  margin-bottom: 8px;
  text-align: center;

  ${media.greaterThan("large")`
    font-size: 36px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #403e3e;
  font-weight: 400;
  margin-bottom: 32px;
  line-height: 150%;
  text-align: center;

  ${media.greaterThan("large")`
    font-size: 18px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ImageCircle = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 28px;
  background-color: #ededed;
  padding: 16px;
`;
