import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  background: #323232;
  border-radius: 28px 28px 0 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  line-height: 150%;
  letter-spacing: 0.2px;

  ${media.greaterThan("medium")`
    font-size: 32px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 32px;
  letter-spacing: -0.2px;

  ${media.greaterThan("medium")`
    font-size: 24px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 0;

  ${media.greaterThan("large")`
    width: 50%;
  `}
`;

export const Button = styled.a`
  border: none;
  background-color: #ffcb32;
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
  height: 56px;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    background-color: #ffcb32;
    color: #000;
  }
`;
