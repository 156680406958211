import {
  Wrapper,
  Content,
  Column,
  Link,
  IconListItem,
  WrapperMainContent,
  Row,
  SectionTitle,
  ListSocialMedia,
  Icon,
  IconImage,
  Button,
} from "./styles";
import { Container } from "@mui/material";

const Footer = () => {
  const PHONE = "5511961550628";

  return (
    <Wrapper>
      <Container>
        <WrapperMainContent>
          <Content>
            <Column>
              <SectionTitle>Baixe já</SectionTitle>
              <Button
                href="http://onelink.to/3u73w5"
                target="_blank"
                rel="noreferrer"
              >
                Download
              </Button>
            </Column>
          </Content>
          <Content>
            <Column>
              <SectionTitle>Entre em contato</SectionTitle>
              <Link
                href={`https://api.whatsapp.com/send?phone=${PHONE}`}
                target="_blank"
                rel="noreferrer"
              >
                +55 11 96155-0628
              </Link>
              <Link href={`mailto:ajuda@camionline.com.br`} rel="noreferrer">
                ajuda@camionline.com.br
              </Link>
            </Column>
          </Content>

          <Content>
            <Column>
              <SectionTitle>Redes Sociais</SectionTitle>
              <Row>
                <ListSocialMedia>
                  <IconListItem>
                    <Icon
                      href="https://www.facebook.com/Cami-Carreto-177856951015323/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconImage
                        src="/img/facebook.png"
                        alt="Logo do Facebook"
                      />
                    </Icon>
                  </IconListItem>
                  <IconListItem>
                    <Icon
                      href="https://www.instagram.com/cami_carreto?igshid=YmMyMTA2M2Y/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconImage
                        src="/img/Instagram.png"
                        alt="Logo do Instagram"
                      />
                    </Icon>
                  </IconListItem>
                  <IconListItem>
                    <Icon
                      href="https://www.linkedin.com/in/cami-carretos-e-mudan%C3%A7as-9299ba21a"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <IconImage
                        src="/img/linkedin.png"
                        alt="Logo do Linkedin"
                      />
                    </Icon>
                  </IconListItem>
                </ListSocialMedia>
              </Row>
            </Column>
          </Content>
        </WrapperMainContent>
      </Container>
    </Wrapper>
  );
};

export default Footer;
