import styled from "styled-components";
import media from "styled-media-query";

export const Section = styled.section`
  width: 100%;
  max-width: 100%;
  margin: 0 auto 80px;
  border-radius: 0px;
`;

export const WrapperDownload = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 16px;
  width: 100%;
  padding: 80px 40px 80px 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #dcdcdc;
  box-shadow: rgba(50, 50, 50, 0.4) 5px 5px, rgba(50, 50, 50, 0.3) 10px 10px,
    rgba(50, 50, 50, 0.2) 15px 15px, rgba(50, 50, 50, 0.1) 20px 20px,
    rgba(50, 50, 50, 0.05) 25px 25px;
`;

export const Button = styled.a`
  border: none;
  background-color: #ffc200;
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;
  min-height: 36px;
  min-width: 300px;
  max-width: 100%;
  position: absolute;
  bottom: 20px;
  left: 50%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  letter-spacing: 0.8px;
  align-items: center;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.05);
  -moz-box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.05);
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.05);

  ${media.greaterThan("large")`
    bottom: 0px;
    position: relative;
    left: 150px;
    max-width: 200px;
  `}

  &:hover {
    background-color: #ffcb32;
    color: #212529;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;
  margin-bottom: 16px;
  letter-spacing: 0.2px;

  ${media.greaterThan("large")`
    font-size: 36px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #212529;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 16px;
  letter-spacing: -0.2px;

  ${media.greaterThan("large")`
    font-size: 24px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReadyImage = styled.img`
  min-height: 200px;
  min-width: 300px;
  width: 100%;
  height: 100%;
`;
