import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  ImageCircle,
} from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionTips = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Content>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <ImageCircle
                    src="/img/money.png"
                    alt="Imagem em formato de circulo representando uma carteira com dinheiro"
                    loading="lazy"
                  />
                </motion.div>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <Title>Receba em pouco tempo</Title>
                  <Subtitle>
                    De uma forma rápida, você pode solicitar seus saques direto
                    pelo aplicativo, e de uma forma segura o valor já é
                    transferido para sua conta. Todas as transferências são
                    feita as quartas feiras da semana.
                  </Subtitle>
                </motion.div>
              </Content>
            </Grid>
            <Grid item xs={12} md={6}>
              <Content>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <ImageCircle
                    src="/img/calendar.png"
                    alt="Imagem em formato de circulo representando um calendário"
                    loading="lazy"
                  />
                </motion.div>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{
                    y: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <Title>Monte a sua própria agenda</Title>
                  <Subtitle>
                    Através da Cami você tem a liberdade de aceitar carretos
                    agendados, com isso você administra o seu tempo de uma forma
                    mais eficiente, já sabendo para onde ir e quando ir.
                  </Subtitle>
                </motion.div>
              </Content>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionTips;
