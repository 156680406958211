import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #ffc200;
  padding: 16px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  box-shadow: rgba(50, 50, 50, 0.3) 2px 2px;
  margin-bottom: 16px;

  & svg {
    fill: #fff;
    color: #fff;
    height: 40px;
    width: 40px;
  }
`;
