import styled from "styled-components";
import media from "styled-media-query";

export const Background = styled.section`
  background-color: #fff;
  padding: 12px 0 0 0;
`;

export const Section = styled.div`
  width: 100%;
  background: linear-gradient(
    160deg,
    rgba(255, 194, 0, 1) 5%,
    rgba(255, 238, 181, 1) 52%,
    rgba(255, 216, 91, 1) 77%,
    rgba(255, 195, 5, 1) 100%,
    rgba(255, 255, 255, 1) 100%
  );
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 80px 40px 80px 40px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
`;

export const Button = styled.button`
  border: none;
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  padding: 8px 32px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #ffcb32;
    color: #000;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: #212529;
  line-height: 150%;
  letter-spacing: 0.2px;

  ${media.greaterThan("large")`
    font-size: 58px;
  `}
`;

export const Subtitle = styled.p`
  font-size: 18px;
  color: #212529;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 32px;
  letter-spacing: -0.2px;

  ${media.greaterThan("large")`
    font-size: 32px;
  `}
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const WrapperImage = styled.div`
  max-height: 200px;
`;

export const TruckImage = styled.img`
  max-width: 400px;
`;

export const PartnerImage = styled.img`
  max-width: 400px;

  ${media.greaterThan("large")`
    max-width: 500px;
  `}
`;

export const WrapperBenefits = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`;

export const WrapperMoneyPig = styled.div`
  display: none;

  ${media.greaterThan("medium")`
    display: flex;
  `}
`;
