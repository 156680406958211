import {
  Wrapper,
  Title,
  Subtitle,
  Section,
  Content,
  VisionImage,
} from "./styles";
import { Container, Grid } from "@mui/material";
import { motion } from "framer-motion";

const SectionAboutCami = () => {
  return (
    <Section>
      <Container>
        <Wrapper>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Content>
                <motion.div
                  viewport={{ once: true }}
                  initial={{ x: -100, opacity: 0 }}
                  whileInView={{
                    x: 0,
                    opacity: 1,
                    transition: {
                      type: "just",
                      duration: 1,
                    },
                  }}
                >
                  <VisionImage
                    src="/img/vision.png"
                    alt="Imagem de um dardo acertando no centro do alvo."
                  />
                </motion.div>
              </Content>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                viewport={{ once: true }}
                initial={{ x: 100, opacity: 0 }}
                whileInView={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "just",
                    duration: 1,
                  },
                }}
              >
                <Title>Visão</Title>
                <Subtitle>
                  Ser uma plataforma de mobilidade líder no mundo, queremos ser
                  parte da rotina dos nossos usuários, a melhor qualidade de
                  vida de uma forma conveniente, acessível e confiável.
                </Subtitle>
              </motion.div>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </Section>
  );
};

export default SectionAboutCami;
